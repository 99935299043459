<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'

export default defineComponent({
  name: 'AddToCart',
  data() {
    return {
      checkoutCode: this.$route.query.checkout_code as string,
    }
  },
  created() {
    if (this.checkoutCode) {
      const split = this.checkoutCode.split(',')
      if (split.length == 1) {
        if (Auth.isLoggedIn()) {
          this.$router.push(
            '/classroom/' + this.checkoutCode + '?checkout=true'
          )
        } else {
          this.$router.push(
            '/register?redirect=/classroom/' +
              this.checkoutCode +
              '?checkout=true'
          )
        }
      } else {
        if (Auth.isLoggedIn()) {
          this.$router.push('/cart?add=' + this.checkoutCode)
        } else {
          this.$router.push('/register?redirect=/cart?add=' + this.checkoutCode)
        }
      }
    } else {
      this.$router.push('/')
    }
  },
  methods: {},
})
</script>

<style scoped>
.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s ease;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
